import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/chcete-udrzet-vahu-krabickovou-dietu.jpg'
import styles from './post-grid.module.css'

const ChceteUdrzetVahuKrabickovouDietu = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Chcete si udržet váhu? Zvolte krabičkovou dietu | NutritionPro'}
        description={
          'Podařilo se vám dosáhnout své vysněné váhy? Gratulujeme, to je skvělé! Dostat se na určité číslo je však jen začátek cesty. Zhubnout či nabrat je jen část úspěchu, druhou částí je váhu si udržet. Zajímá vás, jaký je nejlepší způsob, jak toho pohodlně dosáhnout? No přece naše krabičky!'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/chcete-udrzet-vahu-krabickovou-dietu">
            Chcete si udržet váhu? Zvolte krabičkovou dietu
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Chcete si udržet váhu? Zvolte krabičkovou dietu"
              date="17.04.2023"
            />
            <div>
              <p className={styles.postText}>
                Podařilo se vám dosáhnout své vysněné váhy? Gratulujeme,{' '}
                <b>to je skvělé</b>! Dostat se na určité číslo je však{' '}
                <b>jen začátek cesty</b>. Zhubnout či nabrat je jen část
                úspěchu, <b>druhou částí je váhu si udržet</b>. Zajímá vás, jaký
                je <b>nejlepší způsob</b>, jak toho pohodlně dosáhnout? No přece{' '}
                <b>
                  <a href="https://nutritionpro.cz/" target="_blank">
                    naše krabičky!
                  </a>
                </b>
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="krabičky na jídlo"
              />

              <p className={styles.postText}>
                Krabičková dieta je hojně využívána jako způsob, jak si udržet
                hmotnost. Každá krabička obsahuje přesně{' '}
                <b>stanovené množství potravin</b>, které tvoří{' '}
                <b>vyvážené a chutné jídlo</b> plné bílkovin, komplexních
                sacharidů a vhodných tuků. Krabičky jsou{' '}
                <b>tvořeny na míru vašemu cíli</b>, pokud se tedy snažíte o
                udržení váhy, zvolíte <b>udržovací program</b> a je to. A proč
                že jsou krabičky pro tuto příležitost vhodné?
              </p>

              <h5 className={styles.postTitle}>Přesné dávkování potravin</h5>
              <p className={styles.postText}>
                Krabičková dieta poskytuje přesně dávkované množství potravin,
                které obsahují <b>optimální množství energie přímo pro vás</b>.
                Lidé často <b>nemají jasnou představu</b> o tom, kolik toho mají
                přes den sníst, snadno se proto může stát, že se buď{' '}
                <b>přejídají nebo jí málo</b>. Krabičková dieta vám zajistí, že
                jídlo bude dávkováno přesně podle vašich potřeb.
              </p>

              <h5 className={styles.postTitle}>Řádné stravovací návyky</h5>
              <p className={styles.postText}>
                Díky krabičkám se naučíte jíst <b>adekvátní porce</b> a také si{' '}
                <b>vytvoříte řádné stravovací návyky</b>. Jíst pravidelně vám
                může pomoci{' '}
                <b>nejen zhubnout či nabrat, ale také si váhu udržet</b>.
                Nesklouznete tak k <b>přejídání či vynechávání jídel</b>.
                Krabičková dieta nabízí{' '}
                <b>adekvátní porce v pravidelných intervalech</b>, což pomáhá
                udržet krevní cukr stabilní a snižuje tak touhu po nezdravých
                potravinách.
              </p>

              <h5 className={styles.postTitle}>
                Výběr zdravých a kvalitních potravin
              </h5>
              <p className={styles.postText}>
                Pokud si nejste úplně jisti, jak by mělo{' '}
                <b>vypadat zdravé stravování</b>, s krabičkovou dietou neuděláte
                chybu. Je možné, že jste zhubli díky některé z extrémních diet a
                teď se snažíte začít <b>jíst normálně</b>, ale nechcete shozená
                kila <b>nabrat zpět</b>. Krabičková dieta poskytuje{' '}
                <b>přesné informace</b> o tom, co jídlo obsahuje, a tím se{' '}
                <b>snižuje riziko</b>, že byste sklouzli k nezdravému
                stravování.
              </p>

              <h5 className={styles.postTitle}>
                Snadná a praktická možnost stravování
              </h5>
              <p className={styles.postText}>
                Krabičková dieta je také velmi <b>praktická a snadná</b>.
                Krabičky jsou připravovány <b>profesionálními kuchaři</b>, kteří
                zajistí <b>vyváženou a zdravou</b> stravu. Jsou přichystány{' '}
                <b>předem</b>, což{' '}
                <b>šetří čas a usnadňuje organizaci stravování</b>. Krabičková
                dieta také nabízí možnost výběru diet, které vyhovují vašim
                potřebám a preferencím. Můžete například vyzkoušet nové{' '}
                <a
                  href="https://nutritionpro.cz/#vege-menu-section"
                  target="_blank"
                >
                  vegetariánské menu.
                </a>
              </p>

              <p className={styles.postText}>
                Vyzkoušejte{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  Nutritionpro krabičky
                </a>{' '}
                a uvidíte, že udržet si váhu může být hračka!
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default ChceteUdrzetVahuKrabickovouDietu
